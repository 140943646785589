import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CaiPopupContext } from 'src/context/CaiPopupContext'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import HeaderButton from './HeaderButton'
import { Icon } from '@iconify/react'
import { isMobile } from 'react-device-detect'

const IS_PROD = process.env.REACT_APP_ENV === 'prod' || false

type Props = {
  newChat: (flow: string) => void
  flow: string
}

function HeaderButtons(props: Props) {
  const { newChat, flow } = props

  const { t } = useTranslation()

  const { newChatRef, librarianRef, feedFwdRef } = useContext(CaiPopupContext)

  return (
    <>
      {!IS_PROD && (
        <HeaderButton
          ref={feedFwdRef}
          className="py-1 px-2 h-100"
          handleClick={() => newChat(CAI_CHAT_FLOWS.FEEDBACK)}
          label={
            <>
              <Icon
                height="24"
                icon="material-symbols:chat-paste-go-outline"
                width="24"
              />
              {!isMobile && t('common.word.communication', 'Communication')}
            </>
          }
          style={{
            borderColor: 'var(--feedback-color)',
            background: 'var(--feedback-color)',
            color: 'black',
            opacity: flow === CAI_CHAT_FLOWS.FEEDBACK ? 1 : 0.7,
          }}
          tooltipProps={{
            message: t(
              'caiPopup.headerButtons.tooltipFeedback.title',
              'Enter FeedForward mode to record and share feedback.',
            ),
          }}
        />
      )}

      <HeaderButton
        ref={librarianRef}
        className="py-1 px-2 h-100"
        handleClick={() => newChat(CAI_CHAT_FLOWS.LIBRARIAN)}
        label={
          <>
            <Icon
              height="24"
              icon="material-symbols-light:book-4-spark-outline-rounded"
              width="24"
            />
            {!isMobile &&
              t('caiPopup.headerButtons.tooltipConnect.button', 'Librarian')}
          </>
        }
        style={{
          borderColor: 'var(--librarian-color)',
          background: 'var(--librarian-color)',
          color: 'black',
          opacity: flow === CAI_CHAT_FLOWS.LIBRARIAN ? 1 : 0.7,
        }}
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipConnect.title',
            'Connect with the Librarian that can explain any official policies and benefits.',
          ),
        }}
      />

      <HeaderButton
        ref={newChatRef}
        className=" h-100 pr-0 pl-2"
        handleClick={() => newChat(CAI_CHAT_FLOWS.DEFAULT)}
        label={<Icon height="24" icon="ri:chat-new-fill" width="24" />}
        style={{
          color: 'var(--color-primary)',
          border: 0,
          background: 'none',
          opacity: flow === CAI_CHAT_FLOWS.DEFAULT ? 1 : 0.7,
          paddingRight: '0',
        }}
        tooltipProps={{
          message: t(
            'caiPopup.headerButtons.tooltipNewChat.title',
            'Start a new chat on a different topic.',
          ),
        }}
      />
    </>
  )
}

export default React.memo(HeaderButtons)
