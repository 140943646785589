import { useTranslation } from 'react-i18next'
import { CAI_CHAT_FLOWS } from '../../../constants/cai-chat.constant'
import Tooltip from '../../UI/Tooltip'

const BUTTONS = [
  {
    id: 'anonymous',
    title: 'caiPopup.feedbackButton.button.anonymous.title',
    tooltip: 'caiPopup.feedbackButton.button.anonymous.tooltip',
  },
]

if (process.env.REACT_APP_ENV === 'dev') {
  BUTTONS.push({
    id: 'direct',
    title: 'caiPopup.feedbackButton.button.direct.title',
    tooltip: 'caiPopup.feedbackButton.button.direct.tooltip',
  })
}

export default function FeedbackButtons({
  form,
  setFlow,
  handleFeedbackButtonsClick,
  disabled,
}) {
  const { t } = useTranslation()

  const handleSubmit = async (values) => {
    setFlow(CAI_CHAT_FLOWS.DEFAULT)
    await handleFeedbackButtonsClick(values)
  }

  const onCheck = async (id) => {
    try {
      const values = await form.validateFields()
      handleSubmit({ ...values, id })
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
    }
  }

  return (
    <div className="d-flex flex-row gap-2 justify-content-end">
      {BUTTONS.map(({ id, title, tooltip }, index) => (
        <Tooltip
          key={title}
          message={
            disabled
              ? 'Please provide a recipient and FeedForward summary before sending.'
              : t(tooltip)
          }
        >
          <span>
            <button
              className="btn"
              disabled={disabled}
              type="submit"
              style={{
                background: index === 1 ? '#B22222' : 'var(--primary-color)',
                color: 'white',
                minWidth: '120px',
                height: '45px',
                padding: '0.5em 1em',
                fontSize: '1rem',
                border: '1px solid black',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
                opacity: disabled ? 0.6 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
              }}
              onClick={() => onCheck(id)}
            >
              {t(title)}
            </button>
          </span>
        </Tooltip>
      ))}
    </div>
  )
}
