import React from 'react'
import myCoachLogo from '../../assets/images/my-coach-logo-2.svg'

const MaintenancePage = () => (
  <div style={{ textAlign: 'center', marginTop: '20%' }}>
    <img src={myCoachLogo} alt="my-coach-logo" style={{ colour: '#622dd3' }} />
    <h1 style={{ color: '#622dd3' }}>MyCoach Is Under Maintenance</h1>
    <p>MyCoach is currently down for scheduled maintenance.</p>
    <p className="small-text">
      We'll be back shortly, thank you for your patience.
    </p>
  </div>
)

export default MaintenancePage
