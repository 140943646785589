import { DefaultError, useSuspenseQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import QUERY_KEYS from 'src/constants/queryKey'
import { Item } from 'src/types/session.type'

export default function useGetSessions() {
  const authContext = useContext(AuthContext)
  const userId = authContext.userInfo?.stsUserId
  const organizationId = authContext.userInfo?.organization?.id

  return useSuspenseQuery<{ data: Item[] }, DefaultError, Item[]>({
    queryKey: [QUERY_KEYS.SESSIONS, userId, organizationId],
    queryFn: ({ signal }) =>
      fetch(
        `${process.env.REACT_APP_CARCH_ENDPOINT}/sessions?user_id=${userId}&organisation_id=${organizationId}`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
      )
        .then((r) => r.json())
        .catch((e: unknown) => {
          throw e
        }),
    select: ({ data }) =>
      [...data].sort(
        (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      ),
  })
}
